@import "~antd/dist/antd.css";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.nav-hero-container {
  /* config */
  background: url("./Media/img/Hero.jpg") no-repeat center top/cover;
  background-size: cover;
  height: 100vh;
  position: relative;
  padding: 0;
  z-index: 100;
}
.nav-hero-container::before {
  content: "";
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
}
.nav {
  opacity: 0.9;
  position: fixed;
  transition: 0.3s ease-in;
  border: none;
  /* config */
  background: #293477;
}
.nav.top {
  background: transparent !important;
}
.nav-item {
  background-color: transparent;
  font-weight: 500;
}
.nav-label {
  font-size: 24px;
  font-weight: 500;
  /* config */
  color: #fff !important;
}
.hero {
  background-color: transparent;
  margin-top: 63px;
}
.hero-col {
  text-align: center;
  align-self: center;
}
.footer-container {
  padding: 0 !important;
  height: 110px;
}
.carousel {
  background-color: black;
}
.batch-container {
  overflow: hidden;
}
.photo-div {
  display: flex;
  justify-content: center;
  padding: 15px;
  min-width: 200px;
}
.photo-img {
  max-height: 350px;
  color: #fff;
  line-height: 160px;
  text-align: center;
  background: #364d79;
}
.footer {
  border: none !important;
  height: 100%;
  /* config */
  background: #293477;
}
.footer-item {
  border: none !important;
  background-color: transparent;
}
.footer-label {
  background: #293477;
  height: 40%;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: none !important;
  transition: none !important;
}
.button {
  margin: 0 0.125em;
}
